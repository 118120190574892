// FilterButton.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import ModalView from '../container/ModalView.js';

import UniversalContainer from '../component/UniversalContainer.jsx';

import { QueryType } from '../resource/feedConstants.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';

import { color, textColor, breakpoint } from '../style/variables.js';
import ResetButtonStyle from '../style/ResetButtonStyle.js';
import TextEllipsis from '../style/TextEllipsis.js';
import media from '../style/media.js';

import FilterIconSource from '../../img/ic_filter.svg';
import FilterIconBlueSource from '../../img/ic_filter_blue.svg';

const MODAL_VIEW_STYLE = {
  position: 'static',
};

export const FilterButton = ({
  modalViewRootId,
  mixpanelButtonId,
  remoteConfigKeyName,
  baseFeedName,
  deviceWidth,
  isFilterMenuOpened,
  isSortMenuOpened,
  selectedOptions,
  optionObject,
  t,
  toggleModal,
  setSelectedFeedQueryDraftOption,
}) => {
  const isMobile = deviceWidth <= breakpoint.mobile;
  const hasSelectedOption = selectedOptions.length > 0;
  if (!Object.keys(optionObject).length) {
    return null;
  }
  return (
    <Wrapper id={modalViewRootId}>
      <StyledFilterButton
        isMenuOpened={isFilterMenuOpened}
        hasSelectedOption={hasSelectedOption}
        data-element_id={mixpanelButtonId}
        onClick={event => {
          event.stopPropagation();
          if (isFilterMenuOpened) {
            return toggleModal({
              modalId: 'FilterMenu',
              isOpen: false,
              payload: {},
              modalOption: {},
            });
          }
          if (isSortMenuOpened) {
            toggleModal({
              modalId: 'SortMenu',
              isOpen: false,
              payload: {},
              modalOption: {},
            });
          }
          setSelectedFeedQueryDraftOption({
            remoteConfigKeyName,
            baseFeedName,
            queryType: QueryType.FILTER,
            isRestore: true,
          });
          return toggleModal({
            modalId: 'FilterMenu',
            isOpen: true,
            payload: {},
            modalOption: {},
          });
        }}
      >
        <ButtonText>
          {t('general_filter', {
            ns: TranslationNamespace.FEED,
          })}
        </ButtonText>
        <img
          src={
            isFilterMenuOpened || hasSelectedOption
              ? FilterIconBlueSource
              : FilterIconSource
          }
          alt="filter icon"
          width="20"
          height="20"
        />
        {hasSelectedOption && (
          <SelectedAmount>{selectedOptions.length}</SelectedAmount>
        )}
      </StyledFilterButton>
      <ModalView
        id="FilterMenu"
        noScrollLock={!isMobile}
        rootId={isMobile ? undefined : `#${modalViewRootId}`}
        modalViewStyle={isMobile ? undefined : MODAL_VIEW_STYLE}
        render={() => (
          <UniversalContainer
            filename="FilterMenu"
            remoteConfigKeyName={remoteConfigKeyName}
            baseFeedName={baseFeedName}
          />
        )}
      />
    </Wrapper>
  );
};

FilterButton.propTypes = {
  modalViewRootId: PropTypes.string,
  mixpanelButtonId: PropTypes.string,
  remoteConfigKeyName: PropTypes.string,
  baseFeedName: PropTypes.string,
  deviceWidth: PropTypes.number,
  isFilterMenuOpened: PropTypes.bool,
  isSortMenuOpened: PropTypes.bool,
  selectedOptions: PropTypes.array,
  optionObject: PropTypes.object,
  toggleModal: PropTypes.func,
  setSelectedFeedQueryDraftOption: PropTypes.func,
  t: PropTypes.func.isRequired,
};

FilterButton.defaultProps = {
  modalViewRootId: 'filter-button-wrapper',
  mixpanelButtonId: '',
  remoteConfigKeyName: null,
  baseFeedName: null,
  deviceWidth: 0,
  isFilterMenuOpened: false,
  isSortMenuOpened: false,
  selectedOptions: [],
  optionObject: {},
  toggleModal: () => null,
  setSelectedFeedQueryDraftOption: () => null,
};

const Wrapper = styled.span`
  position: relative;
`;

const StyledFilterButton = styled.button.attrs(
  ({ isMenuOpened, hasSelectedOption }) => ({
    style: {
      borderColor: isMenuOpened || hasSelectedOption ? color.sky : color.white,
      color:
        isMenuOpened || hasSelectedOption
          ? textColor.secondary
          : textColor.white,
      backgroundColor: isMenuOpened ? 'rgba(96, 196, 232, 0.3)' : 'transparent',
    },
  })
)`
  ${ResetButtonStyle};
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding: 0px 8px;
  height: 32px;
  max-width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  transition:
    border-color 0.4s,
    color 0.4s;
  > img {
    margin-left: 4px;
    width: 16px;
    height: 16px;
    flex: none;
    object-fit: contain;
  }
  ${media.mobile`
    height: 24px;
    max-width: 160px;
    font-size: 12px;
    >img {
      width: 20px;
      height: 20px;
    }
  `};
`;

const ButtonText = styled.div`
  ${TextEllipsis};
`;

const SelectedAmount = styled.div`
  margin-left: 4px;
  font-weight: 600;
`;

export default withTranslation()(FilterButton);
